nav {
  font-family: var(--mono);
  font-size: 80%;
  padding: 4rem 1rem;
}

nav h1 a {
  font-family: var(--sans);
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

nav li:first-child {
  flex-basis: 100%;
  text-align: center;
}

nav [class*="fa-"] {
  font-size: 150%;
  color: var(--aqua);
}

nav h1 [class*="fa-"] {
  font-size: 100%;
  color: var(--aqua);
}

nav a {
  color: white;
  text-decoration: none;
  display: block;
}

nav a:hover,
nav [class*="fa-"]:hover {
  color: var(--magenta);
}

.button {
  background-color: var(--magenta);
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-family: var(--mono);
}

.button:hover {
  color: white;
  background-color: var(--hotmag);
}
.hidden {
  display: none;
}
@media (max-width: 400px) {
  nav .top-nav {
    font-size: 0.65rem;
  }
}
