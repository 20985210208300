/* Variables declared here - these are the colors for our pages, as well as the font stacks and sizes. */

:root {
  --black: #171321;
  --dkblue: #0d314b;
  --plum: #4b0d49;
  --hotmag: #ff17e4;
  --magenta: #e310cb;
  --aqua: #86fbfb;
  --white: #f7f8fa;
  --font-size: 1.3rem;
  --mono: "Oxygen mono", monospace;
  --sans: Oxygen, sans-serif;
}

/* border box model: https://css-tricks.com/box-sizing/ */

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* generic styles for the page */

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: var(--sans);
  background-color: var(--black);
  color: var(--white);
  font-size: var(--font-size);
}

h1,
h2,
h3 {
  margin: 0;
}

a {
  color: var(--magenta);
}

a:hover {
  color: var(--hotmag);
  text-decoration: none;
}
img {
  width: 100%;
}

.about-image {
  width: auto;
  height: 400px;
}

/* background color divs */

.section-blue {
  background-color: var(--dkblue);
}

.gradient {
  background: linear-gradient(
    90deg,
    rgba(255, 23, 228, 1) 0%,
    rgba(134, 251, 251, 1) 100%
  );
  height: 2px;
  width: 100%;
}

/* intro styles */

#intro {
  padding: 4rem 1rem 10rem 1rem;
  padding-bottom: 10rem;
  max-width: 1200px;
  margin: 0 auto;
}

#intro p {
  font-size: 1rem;
  line-height: 1.5;
}

#intro .name {
  font-family: var(--mono);
  font-size: 1rem;
}

#intro .highlight {
  font-size: 1.5rem;
  font-weight: bold;
}

#intro .description {
  font-size: 0.9rem;
  line-height: 1.4;
}

.name span {
  font-family: var(--sans);
  font-size: 4rem;
  color: var(--aqua);
  display: block;
  font-weight: 300;
}

#intro h2 {
  font-size: 4rem;
}

/* contact section */

#contact {
  width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 4rem 0;
}

#contact p:last-child {
  margin-top: 3rem;
}

/* navigation */

nav {
  font-family: var(--mono);
  font-size: 80%;
  padding: 4rem 1rem;
}

nav h1 a {
  font-family: var(--sans);
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

nav li:first-child {
  flex-basis: 100%;
  text-align: center;
}

nav [class*="fa-"] {
  font-size: 150%;
  color: var(--aqua);
}

nav h1 [class*="fa-"] {
  font-size: 100%;
  color: var(--aqua);
}

nav a {
  color: white;
  text-decoration: none;
  display: block;
}

nav a:hover,
nav [class*="fa-"]:hover {
  color: var(--magenta);
}

.button {
  background-color: var(--magenta);
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-family: var(--mono);
}

.button:hover {
  color: white;
  background-color: var(--hotmag);
}

/* projects section */
#projects {
  padding: 4rem 1rem;
}
#projects h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}
#projects h3 {
  color: var(--aqua);
}

#projects h4 {
  font-size: 1rem;
  font-family: var(--mono);
  margin: 0;
}
.blackbox {
  padding: 1rem;
  border-radius: 10px;
  color: white;
  background-color: var(--black);
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
}
#projects ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1rem;
}
#projects img {
  margin: 2rem 0 4rem 0;
  padding: 1rem;
  border-left: 1px solid var(--aqua);
  border-top: 1px solid var(--aqua);
  border-radius: 25px;
}
/* footer section */

footer {
  text-align: center;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
  display: flex;
  flex-direction: row; /* Iconları yan yana dizmek için */
  justify-content: center;
  gap: 3rem;
  font-size: 3rem;
}

@media (max-width: 600px) {
  .projects-container {
    padding: 2rem 1rem;
  }

  .projects-container h2 {
    font-size: 1.5rem;
  }

  .projects-container p {
    font-size: 0.9rem;
  }

  #intro h2 {
    font-size: 2.5rem;
  }

  .about-image {
    height: auto;
    width: 100%;
  }
}

@media (min-width: 800px) {
  article {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }
  #projects {
    max-width: 1200px;
    margin: 0 auto;
  }
  #projects img {
    grid-column: 1/6;
    grid-row: 1/2;
  }
  .text {
    grid-column: 5/11;
    grid-row: 1/2;
    order: 2;
    text-align: right;
  }
  #projects ul {
    justify-content: flex-end;
  }
  #projects .reverse .text {
    grid-column: 1/7;
    order: 2;
    text-align: left;
  }
  #projects .reverse img {
    grid-column: 6/11;
    grid-row: 1/2;
  }
  #projects .reverse ul {
    justify-content: flex-start;
  }
}
@media (min-width: 850px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
  }
  nav li:first-child {
    flex-basis: auto;
    text-align: left;
    margin-right: auto;
  }
}
.home-container {
  background-color: var(--dkblue);
  max-width: 100vw;
}
@media (max-width: 600px) {
  .gradient {
    width: 100vw;
  }
  #intro .highlight {
    font-size: 1rem;
  }
  .bottom-footer #alp {
    font-size: 1.5rem;
  }
}
@media (max-width: 320px) {
  .gradient {
    width: 100vw;
  }
  #intro .highlight {
    font-size: 0.8rem;
  }
  .bottom-footer #alp {
    font-size: 1.3rem;
  }
}
