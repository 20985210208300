.about-container {
  text-align: center;
  padding: 2rem 0;
}

.about-content {
  display: inline-block;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.about-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  margin: 1rem 0;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 1rem 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 0.5rem 0;
}
