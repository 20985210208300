.contact-container {
  background-color: var(--dkblue);
  padding: 4rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
}
#contact {
  background-color: var(--dkblue);
}
#contact h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--aqua);
}

#contact p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

form {
  background-color: var(--dkblue);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

label {
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  color: var(--white);
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: none;
  border-radius: 5px;
}

button {
  background-color: var(--magenta);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}

button:hover {
  background-color: var(--hotmag);
}

.social-links ul {
  list-style-type: none;
  padding: 0;
  margin: 2rem 0 0 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.social-links li {
  font-size: 3rem;
}
